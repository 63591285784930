// import './App.css';
// import Navbar from "./Navbar";
// import AddSpell from "./AddSpell";
// import ViewSpell from "./ViewSpell";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import axios from 'axios';

// axios.defaults.xsrfCookieName = 'csrftoken'
// axios.defaults.xsrfHeaderName = 'X-CSRFToken'
// function App() {
//   return (
//     <div>

//       <Router>
//         <Navbar />
//         <Switch>
//           <Route path="/addSpell" component={AddSpell} />
//           <Route path="/viewSpell" component={ViewSpell} />
//         </Switch>
//       </Router>
//     </div>
//   );
// }

// export default App;

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Legal from './Legal';
import './static/css/index.scss';
const App = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='legal' element={<Legal />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;

